<template>
  <div>
    <panel :title="'Yard data' | translate" noButton="true">
      <b-form>
        <b-form-row>
          <form-thux-horizontal-select
            :validator="$v.form.work_status"
            :external-errors="errors['work_status']"
            :label-form="'Status of work' | translate"
            class-form="col-lg-6 mb-2 mt-2"
            labelColsSm="3"
            labelColsLg="3"
            label-search="text"
            :value="form.work_status ? { value: form.work_status, text: form.work_status_name } : null"
            :options="workStatusListOptions"
            :placeholder="$t('Select an option')"
            :disabled="!formEditableUpdate"
            @select="selectWorkStatus"
            @clear="clearWorkStatus"
          />
          <form-thux-horizontal-input
            v-if="form.quotation && form.id"
            :label-form="'Quotation' | translate"
            class-form="col-lg-6 my-2"
            labelColsSm="3"
            labelColsLg="3"
            :value="form.quotation_number"
            :disabled="true"
          />
        </b-form-row>
        <b-form-row>
          <form-thux-horizontal-input
            :validator="$v.form.year"
            :external-errors="errors['year']"
            :label-form="'Year' | translate"
            class-form="col-lg-3 my-2"
            labelColsSm="6"
            labelColsLg="6"
            :value="form.year"
            :disabled="!formEditableUpdate"
            @change="setYardYear"
          />
          <form-thux-horizontal-input
            :validator="$v.form.number"
            :external-errors="errors['number']"
            :label-form="'Number' | translate"
            class-form="col-lg-3 my-2"
            labelColsSm="6"
            labelColsLg="6"
            :value="form.number"
            :disabled="!formEditableUpdate"
            @change="setYardNumber"
          />
          <form-thux-horizontal-input
            :validator="$v.form.code"
            :external-errors="errors['code']"
            :label-form="'Code' | translate"
            class-form="col-lg-6 my-2"
            labelColsSm="3"
            labelColsLg="3"
            :value="form.code"
            :disabled="!formEditableUpdate"
            @change="$set(form, 'code', $event)"
          />
        </b-form-row>
        <b-form-row>
          <form-thux-horizontal-input
            :validator="$v.form.name"
            :external-errors="errors['name']"
            :label-form="'Yard name' | translate"
            class-form="col-lg-12 my-2"
            label-form-class="col-half-width"
            labelColsSm="3"
            labelColsLg="3"
            :value="form.name"
            :disabled="!formEditableUpdate"
            @change="$set(form, 'name', $event)"
          />
        </b-form-row>
        <b-form-row>
          <form-thux-horizontal-multiselect
            :validator="$v.form.clients"
            :external-errors="errors['clients']"
            :label-form="'Yard Clients' | translate"
            class-form="col-lg-6 mb-2 mt-2"
            labelColsSm="3"
            labelColsLg="3"
            :disabled="quotation && quotation.id !== undefined || !formEditableUpdate"
            :label-search="'name'"
            :value="form.clients"
            :options="clientsListOptions"
            :placeholder="
              $t('Select a {instance}', { instance: $t('clients') })
            "
            @search="onSearchClients"
            @remove="removeClients"
            @select="selectClients"
            @clear="$set(row, 'clients', null)"
          />
          <form-thux-horizontal-autocomplete
            :validator="$v.form.customer"
            :label-form="'Customer' | translate"
            class-form="col-lg-6 my-2"
            :disabled="quotation && quotation.id !== undefined || !formEditableUpdate"
            :label-search="'text'"
            labelColsSm="3"
            labelColsLg="3"
            :value="form.customer ? { value: form.customer, text: form.customer_name } : null"
            :options="yardCustomerListOptions"
            :placeholder="$t('Type a customer')"
            :placeholder-field="form.customer_name"
            @search="onSearchYardCustomer"
            @select="selectYardCustomer"
            @clear="clearYardCustomer"
          />
        </b-form-row>
        <b-form-row>
          <form-thux-horizontal-input
            :validator="$v.form.total_inspections"
            :external-errors="errors['total_inspections']"
            :label-form="'Total inspections' | translate"
            class-form="col-lg-6 my-2"
            type="number"
            min="0"
            labelColsSm="3"
            labelColsLg="3"
            :value="form.total_inspections"
            :disabled="!formEditableUpdate"
            @change="$set(form, 'total_inspections', $event)"
          />
        </b-form-row>
        <b-form-row>
           <form-thux-horizontal-text-area
            :validator="$v.form.description"
            :label-form="'Work subject' | translate"
            class-form="col-12 my-2"
            label-form-class="col-half-width"
            labelColsSm="3"
            labelColsLg="3"
            rows="3"
            max-rows="6"
            :value="form.description"
            :disabled="!formEditableUpdate"
            @change="$set(form, 'description', $event)"
          />
        </b-form-row>
        <b-form-row>
          <form-thux-horizontal-datetime
            :validator="$v.form.date_start"
            :label-form="'Date begin' | translate"
            labelColsSm="3"
            labelColsLg="3"
            :disabled="!formEditableUpdate"
            class-form="col-lg-6 my-2"
            type="date"
            :value="dateStart"
            :placeholder="$t('Enter the date')"
            @change="dateChange($event, 'date_start')"
          />
          <form-thux-horizontal-datetime
            :validator="$v.form.date_end"
            :label-form="'Date end' | translate"
            labelColsSm="3"
            labelColsLg="3"
            :disabled="!formEditableUpdate"
            class-form="col-lg-6 my-2"
            type="date"
            :value="dateEnd"
            :min-date="form.date_start"
            :placeholder="$t('Enter the date')"
            @change="dateChange($event, 'date_end')"
          />
        </b-form-row>
        <b-form-row>
          <form-thux-horizontal-datetime
            :validator="$v.form.date_confirmation"
            :label-form="'Confirmation date' | translate"
            labelColsSm="3"
            labelColsLg="3"
            :disabled="!formEditableUpdate"
            class-form="col-lg-6 my-2"
            type="date"
            :value="dateConfirmation"
            :placeholder="$t('Enter the date')"
            @change="setDateStart"
          />
          <form-thux-horizontal-datetime
            :validator="$v.form.date_activation"
            :label-form="'Activation date' | translate"
            labelColsSm="3"
            labelColsLg="3"
            :disabled="!formEditableUpdate"
            class-form="col-lg-6 my-2"
            type="date"
            :value="dateActivation"
            :placeholder="$t('Enter the date')"
            @change="dateChange($event, 'date_activation')"
          />
        </b-form-row>
        <b-form-row>
           <form-thux-horizontal-text-area
            :validator="$v.form.note"
            :label-form="'Notes' | translate"
            class-form="col-12 my-2"
            label-form-class="col-half-width"
            labelColsSm="3"
            labelColsLg="3"
            rows="3"
            :value="form.note"
            :disabled="!formEditableUpdate"
            @change="$set(form, 'note', $event)"
          />
        </b-form-row>
        <b-form-row>
           <form-thux-horizontal-text-area
            :validator="$v.form.to_do"
            :label-form="'To do' | translate"
            class-form="col-12 my-2"
            label-form-class="col-half-width"
            labelColsSm="3"
            labelColsLg="3"
            rows="3"
            :value="form.to_do"
            :disabled="!formEditableUpdate"
            @change="$set(form, 'to_do', $event)"
          />
        </b-form-row>
      </b-form>
    </panel>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import { TYPES as YARD_TYPES } from '../store'

import moment from 'moment'

import ThuxDetailPanelMixin from '../../../../components/thux-detail-panel/ThuxDetailPanelMixin'
import WorkStatusMixin from '../mixins/WorkStatusMixin'
import CustomerMixin from '../../../quotation/quotation/mixins/CustomerMixin'
import YardCustomerMixin from '../mixins/YardCustomerMixin'
import { cloneDeep } from 'lodash'
import ClientsMixin from '../../../proposal/proposal/mixins/ClientsMixin'

export default {
  name: 'QuotationEditForm',
  mixins: [ThuxDetailPanelMixin, WorkStatusMixin, CustomerMixin, YardCustomerMixin, ClientsMixin],
  props: {
    quotation: undefined,
    formEditableUpdate: {},
    resetForm: {}
  },
  watch: {
    form: {
      handler () {
        this.$emit('set-form', this.form)
      },
      deep: true
    },
    quotation () {
      this.init()
    },
    resetForm () {
      this.init()
    },
    id () {
      this.init()
    }
  },
  data () {
    return {
      dateStart: undefined,
      dateEnd: undefined,
      dateConfirmation: undefined,
      dateActivation: undefined,
      supplierContacts: []
    }
  },
  computed: {
    ...mapGetters({
      ...YARD_TYPES.GENERIC.yard.yard.DETAIL.GETTERS
    })
  },
  methods: {
    init () {
      if (this.quotation && this.quotation.id) {
        const form = {}
        this.$set(form, 'quotation', this.quotation.id)
        this.$set(form, 'clients', this.quotation.clients)
        this.$set(form, 'customer', this.quotation.customer)
        this.$set(form, 'customer_name', this.quotation.customer_name)
        this.$set(form, 'description', this.quotation.description)
        this.$set(form, 'name', this.quotation.proposal_yard_name)
        if (form.clients && form.clients.length > 0) {
          const filters = {}
          filters.filter__id__in = [form.clients]
          this.getClientsList(filters).then(() => {
            this.$set(form, 'clients', this.getClients(form))
            this.$set(this, 'form', form)
          })
        } else {
          this.$set(this, 'form', form)
        }
      } else if (this.id) {
        this.makeFormReadonly()
        this.setForm()
      } else {
        this.makeFormEditable()
      }
    },
    setForm () {
      const form = Object.assign({}, this.detail)
      if (this.detail.date_start) {
        this.$set(this, 'dateStart', moment(new Date(this.detail.date_start)))
      }
      if (this.detail.date_end) {
        this.$set(this, 'dateEnd', moment(new Date(this.detail.date_end)))
      }
      if (this.detail.date_confirmation) {
        this.$set(this, 'dateConfirmation', moment(new Date(this.detail.date_confirmation)))
      }
      if (this.detail.date_activation) {
        this.$set(this, 'dateActivation', moment(new Date(this.detail.date_activation)))
      }
      this.$set(this, 'form', form)
      if (form.clients && form.clients.length > 0) {
        const filters = {}
        filters.filter__id__in = [form.clients]
        this.getClientsList(filters).then(() => {
          this.$set(form, 'clients', this.getClients(form))
          this.$set(this, 'form', form)
        })
      } else {
        this.$set(this, 'form', form)
      }
    },
    deleteInstance (instanceName, instanceToDeleteName, instanceToReplaceName) {
      let list = cloneDeep(this.form[instanceName])
      if (this[instanceToReplaceName]) {
        this.$set(list, this[instanceToReplaceName].index, this[instanceToReplaceName].value)
      } else {
        list = list.filter((instance) => {
          return this[instanceToDeleteName].id !== instance.id
        })
        if (!list) list = []
      }
      this.$set(this.form, instanceName, list)
      this.closeConfirmModal()
    },
    setDateStart (evt) {
      const date = evt.date
      if (date) {
        this.$set(this.form, 'date_start', date.format('YYYY-MM-DD'))
      } else {
        this.$set(this.form, 'date_start', undefined)
      }
      if (this.dateStart && date > this.dateEnd) {
        this.$set(this.form, 'date_end', undefined)
        this.$set(this, 'dateEnd', null)
      }
    },
    setYardYear (evt) {
      this.$set(this.form, 'year', evt)
      if (this.form.year && this.form.number) {
        this.$set(this.form, 'code', `C-${this.form.year}-${this.form.number.toString().padStart(2, '0')}`)
      }
    },
    setYardNumber (evt) {
      this.$set(this.form, 'number', evt)
      if (this.form.year && this.form.number) {
        this.$set(this.form, 'code', `C-${this.form.year}-${this.form.number.toString().padStart(2, '0')}`)
      }
    }
  },
  validations () {
    const vals = {
      form: {
        work_status: {},
        year: { required },
        number: { required },
        code: { required },
        name: {},
        clients: { required },
        customer: { required },
        total_inspections: {},
        description: { required },
        date_start: { required },
        date_end: { required },
        technicians: {},
        contractors: {},
        controlBodies: {},
        date_confirmation: {},
        date_activation: {},
        note: {},
        to_do: {}
      }
    }
    if (this.form.work_status === 'CON') {
      vals.form.date_confirmation = { required }
    }
    if (this.form.work_status === 'OP') {
      vals.form.date_activation = { required }
    }
    if (this.form.work_status === 'CMP') {
      vals.form.date_end = { required }
    }
    return vals
  }
}
</script>
