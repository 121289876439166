<template>
  <div>
    <panel :title="'Technicians' | translate" noButton="true">
      <div class="col-12 technician-items panel-separator px-0" v-for="(technician, index) in technicians" :key="index">
        <technician-form
          :id="id"
          :technicians="technicians"
          :form-editable-update="formEditableUpdate"
          :form-update="technician"
          :show-add-button="index === technicians.length - 1"
          :technicians-length="technicians.length"
          :index="index"
          @add-yard-technician="addNewYardTechnician"
          @edit-yard-technician="editYardTechnician($event, index)"
          @show-delete-modal="showConfirmModal($event, index)"
          @remove-yard-technician="showConfirmModal({ instance: $event }, index, true)"
        />
      </div>
    </panel>
    <ConfirmDialog
      v-if="showModal && technicianToDelete.value && technicianToDelete.value.technician"
      ref-id="modal-delete"
      :title="$t('Are you sure to remove this technician?')"
      :button-confirm-text="'Remove' | translate"
      @confirm-dialog-close="closeConfirmModal"
      @confirm-dialog-select="removeOrReplaceTechnician()"
    >
        <template slot="body">
          <div class="mb-8">
            <span style="font-size: 1.2rem">
              {{ $t("To make the changes effective you need to press the 'Save' button at the bottom page") }}
            </span>
          </div>
          <b-table
            no-local-sorting
            show-empty
            striped
            hover
            responsive
            :items="[technicianToDelete.value]"
            :fields="technicianFields"
          >
            <template slot="cell(technician_supplier_role_list)" slot-scope="row">
              <div v-if="row.item.technician_supplier_role_list">
                <span
                  v-for="role in row.item.technician_supplier_role_list"
                  :key="role.id"
                  class="tag"
                >
                <span>
                  {{ role.name }}
                </span>
                </span>
              </div>
            </template>
          </b-table>
      </template>
    </ConfirmDialog>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { cloneDeep } from 'lodash'

import TechnicianForm from './TechnicianForm'
import ConfirmDialog from '../../../../../components/ConfirmDialog'

export default {
  name: 'TechnicianManager',
  props: {
    id: {},
    formEditableUpdate: {},
    techniciansList: [],
    resetTechnicians: {}
  },
  components: {
    TechnicianForm,
    ConfirmDialog
  },
  watch: {
    technicians: {
      handler () {
        let technicians = [...this.technicians]
        technicians = technicians.filter((technician) => {
          return Object.values(technician).some((value) => { return !!value })
        })
        this.$emit(
          'update-technicians',
          technicians,
          this.techniciansToDeleted,
          this.techniciansToChanged
        )
      },
      deep: true
    },
    resetTechnicians () {
      this.init()
    }
  },
  data () {
    return {
      technicians: [{}],
      techniciansToDeleted: new Set(),
      techniciansToChanged: new Set(),
      showModal: false,
      technicianToDelete: {},
      technicianToReplace: null,
      technicianFields: [
        {
          key: 'technician_first_name',
          label: this.$t('First name'),
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap'
        },
        {
          key: 'technician_last_name',
          label: this.$t('Last name'),
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap'
        },
        {
          key: 'technician_supplier_role_list',
          label: this.$t('Roles'),
          tdClass: 'text-nowrap',
          thClass: 'text-nowrap'
        }
      ]
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      if (this.techniciansList && this.techniciansList.length > 0) {
        this.$set(this, 'technicians', cloneDeep(this.techniciansList))
      } else {
        this.$set(this, 'technicians', [{}])
      }
    },
    addNewYardTechnician () {
      this.technicians.push({})
    },
    editYardTechnician (yardTechnician, index) {
      if (yardTechnician.id) {
        this.techniciansToChanged.add(yardTechnician.id)
      }
      this.$set(this.technicians, index, yardTechnician)
    },
    removeOrReplaceTechnician () {
      if (!this.technicianToDelete.deleteYardTechnician) {
        if (this.technicianToReplace) {
          this.$set(this.technicians[this.technicianToReplace.index], 'technician', this.technicianToReplace.value.technician)
          this.$set(this.technicians[this.technicianToReplace.index], 'technician_first_name', this.technicianToReplace.value.technician_first_name)
          this.$set(this.technicians[this.technicianToReplace.index], 'technician_last_name', this.technicianToReplace.value.technician_last_name)
          this.$set(this.technicians[this.technicianToReplace.index], 'technician_supplier_role_list', this.technicianToReplace.value.technician_supplier_role_list)
        } else {
          this.$set(this.technicians[this.technicianToDelete.index], 'technician', null)
          this.$set(this.technicians[this.technicianToDelete.index], 'technician_first_name', null)
          this.$set(this.technicians[this.technicianToDelete.index], 'technician_last_name', null)
          this.$set(this.technicians[this.technicianToDelete.index], 'technician_supplier_role_list', null)
        }
        if (this.technicianToDelete.value?.id) {
          this.techniciansToChanged.add(this.technicianToDelete.value.id)
        }
      } else {
        if (this.technicianToDelete.value?.id) {
          this.techniciansToDeleted.add(this.technicianToDelete.value.id)
          this.techniciansToChanged.delete(this.technicianToDelete.value.id)
        }
        if (this.technicians.length === 1) {
          this.technicians[0] = {}
        } else {
          if (this.technicianToReplace) {
            this.$set(this.technicians[this.technicianToReplace.index], 'technician', this.technicianToReplace.value.technician)
            this.$set(this.technicians[this.technicianToReplace.index], 'technician_first_name', this.technicianToReplace.value.technician_first_name)
            this.$set(this.technicians[this.technicianToReplace.index], 'technician_last_name', this.technicianToReplace.value.technician_last_name)
            this.$set(this.technicians[this.technicianToReplace.index], 'technician_supplier_role_list', this.technicianToReplace.value.technician_supplier_role_list)
          } else {
            this.technicians.splice(this.technicianToDelete.index, 1)
          }
        }
      }
      this.closeConfirmModal()
    },
    showConfirmModal ({ instance, newInstance }, index, deleteYardTechnician = false) {
      if (this.id && instance.id) {
        this.$set(this, 'technicianToDelete', { index: index, value: instance })
        if (deleteYardTechnician) {
          this.$set(this, 'technicianToDelete', { index: index, value: instance, deleteYardTechnician: deleteYardTechnician })
        }
        if (newInstance) {
          this.$set(this, 'technicianToReplace', { index: index, value: newInstance })
        }
        this.$set(this, 'showModal', true)
      } else {
        if (this.technicians.length === 1 && deleteYardTechnician) {
          this.technicians[0] = {}
        } else if (this.technicians.length > 1 && deleteYardTechnician) {
          this.technicians.splice(index, 1)
        } else {
          if (newInstance) {
            this.$set(this.technicians[index], 'technician', newInstance.technician)
            this.$set(this.technicians[index], 'technician_first_name', newInstance.technician_first_name)
            this.$set(this.technicians[index], 'technician_last_name', newInstance.technician_last_name)
            this.$set(this.technicians[index], 'technician_supplier_role_list', newInstance.technician_supplier_role_list)
          } else {
            this.$set(this.technicians[index], 'technician', null)
            this.$set(this.technicians[index], 'technician_first_name', null)
            this.$set(this.technicians[index], 'technician_last_name', null)
            this.$set(this.technicians[index], 'technician_supplier_role_list', null)
          }
        }
      }
    },
    closeConfirmModal () {
      this.$set(this, 'technicianToDelete', {})
      this.$set(this, 'technicianToReplace', null)
      this.$set(this, 'showDeleteModal', false)
    }
  },
  validations: {
    technicians: {
      $each: {
        technician: { required },
        date_yard_entry: {}
      }
    }
  }
}
</script>
