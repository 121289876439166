import { render, staticRenderFns } from "./TechnicianForm.vue?vue&type=template&id=5e96b04d&"
import script from "./TechnicianForm.vue?vue&type=script&lang=js&"
export * from "./TechnicianForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports