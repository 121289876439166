<template>
  <b-form>
    <b-form-row>
      <form-thux-horizontal-autocomplete
        :validator="$v.form.technician"
        :label-form="'Technician' | translate"
        :disabled="!formEditableUpdate"
        :label-search="'name'"
        labelColsSm="0"
        labelColsLg="0"
        class-form="input-row-col mb-2 mt-2"
        :value="form.technician ? { id: form.technician, name: `${form.technician_first_name} ${form.technician_last_name}` } : null"
        :options="techniciansListOptions"
        :placeholder="$t('Search by last name')"
        :placeholder-field="form.technician ?  `${form.technician_first_name} ${form.technician_last_name}` : null"
        @search="onSearchTechnician"
        @select="selectTechnician($event)"
        @clear="clearTechnician()"
      />
       <form-thux-horizontal-multiselect
        :label-form="$t('Roles')"
        label-form-class="col-half-width"
        class-form="input-row-col mb-2 mt-2 ml-2"
        label-search="name"
        labelColsSm="0"
        labelColsLg="0"
        :disabled="true"
        :value="form.technician_supplier_role_list"
        :options="form.technician_supplier_role_list || []"
      />
      <!-- <form-thux-horizontal-datetime
        :validator="$v.form.date_yard_entry"
        label-form=""
        labelColsSm="0"
        labelColsLg="0"
        class-form="input-row-col mb-2 mt-2 ml-2"
        type="date"
        :disabled="!formEditableUpdate"
        :value="dateYardEntry"
        :placeholder="$t('Enter the date yard entry')"
        @change="dateChange($event, 'date_yard_entry')"
      /> -->
      <b-btn-group class="col-xl-1 btn-row-actions my-2">
        <b-btn
          v-if="showAddButton"
          class="mr-1"
          variant="primary"
          :disabled="$v.form.$invalid || !formEditableUpdate"
          @click="addYardTechnician
        ">
          <i class="fas fa-plus"></i>
        </b-btn>
        <b-btn class="mr-1" variant="outline-primary" @click="removeYardTechnician"
          :disabled="!formEditableUpdate">
          <i :class="index === 0 && techniciansLength === 1 ? 'fas fa-eraser' : 'fas fa-minus'"></i>
        </b-btn>
      </b-btn-group>
    </b-form-row>
    <!-- <b-form-row>

    </b-form-row> -->
  </b-form>
</template>

<script>
import ThuxDetailPanelMixin from '../../../../../components/thux-detail-panel/ThuxDetailPanelMixin'
import TechnicianMixin from '../../mixins/TechnicianMixin'
import { required } from 'vuelidate/lib/validators'
import moment from 'moment'

export default {
  name: 'TechnicianForm',
  mixins: [ThuxDetailPanelMixin, TechnicianMixin],
  props: {
    formUpdate: {},
    index: {},
    techniciansLength: {},
    showAddButton: {},
    formEditableUpdate: {}
  },
  watch: {
    formUpdate: {
      handler () {
        if (this.formUpdate) {
          this.$set(this, 'form', this.formUpdate)
          if (this.form.date_yard_entry) {
            this.$set(this, 'dateYardEntry', moment(new Date(this.form.date_yard_entry)))
          } else {
            this.$set(this, 'dateYardEntry', null)
          }
          if (this.form.date_yard_exit) {
            this.$set(this, 'dateYardExit', moment(new Date(this.form.date_yard_exit)))
          } else {
            this.$set(this, 'dateYardExit', null)
          }
        }
      },
      deep: true
    }
  },
  data () {
    return {
      form: {},
      dateYardEntry: undefined
    }
  },
  methods: {
    init () {
      if (this.formUpdate) {
        this.$set(this, 'form', this.formUpdate)
        if (this.form.date_yard_entry) {
          this.$set(this, 'dateYardEntry', moment(new Date(this.form.date_yard_entry)))
        }
        if (this.form.date_yard_exit) {
          this.$set(this, 'dateYardExit', moment(new Date(this.form.date_yard_exit)))
        }
      }
    },
    dateChange (evt, fieldName, format = 'YYYY-MM-DD') {
      const date = evt.date
      if (date) {
        this.$set(this.form, fieldName, date.format(format))
      } else {
        this.$set(this.form, fieldName, undefined)
      }
      this.$emit('edit-yard-technician', this.form)
    },
    checkIfTechnicianEmpty () {
      if (this.form.technician && this.form.technician !== '') return false
      if (this.form.date_yard_entry && this.form.date_yard_entry !== '') return false
      return true
    },
    addYardTechnician () {
      this.$emit('add-yard-technician', this.$v.form.$model)
    },
    removeYardTechnician () {
      if (this.techniciansLength === 1 && (!this.id || !this.form.id)) {
        this.$set(this, 'form', {})
        this.$set(this, 'dateYardEntry', null)
        this.$set(this, 'dateYardExit', null)
      }
      this.$emit('remove-yard-technician', this.$v.form.$model)
    }
  },
  validations () {
    let form = {
      technician: { required },
      date_yard_entry: {}
    }
    if (this.checkIfTechnicianEmpty()) {
      form = {
        technician: {},
        date_yard_entry: {}
      }
    }
    return { form: form }
  }
}
</script>
