<template>
  <div v-has-perms="rolePerm">
    <yard-edit-form
      :id="detail.id"
      :form-editable-update="formEditable"
      :reset-form="resetForm"
      @set-form="updateForm"
      :quotation="quotationDetail"
    />
    <technician-manager
      v-if="initializeFormTechnicians"
      v-has-perms="technicianManagerRolePerm"
      :id="detail.id"
      :form-editable-update="formEditable"
      :technicians-list="formTechniciansInitial"
      :reset-technicians="resetFormTechnicians"
      @update-technicians="updateTechnicians"
    />
    <contractor-manager
      v-if="initializeFormContractors"
      v-has-perms="contractorManagerRolePerm"
      :id="detail.id"
      :form-editable-update="formEditable"
      :contractors-list="formContractorsInitial"
      :reset-contractors="resetFormContractors"
      @update-contractors="updateContractors"
    />
    <control-body-manager
      v-if="initializeFormControlBodies"
      v-has-perms="controlBodyManagerRolePerm"
      :id="detail.id"
      :form-editable-update="formEditable"
      :control-bodies-list="formControlBodiesInitial"
      :reset-control-bodies="resetFormControlBodies"
      @update-control-bodies="updateControlBodies"
    />
    <yard-address-form
      v-has-perms="yardAddressRolePerm"
      :id="yardAddressDetail.id"
      :form-editable-update="formEditable"
      :detail="yardAddressDetail"
      :reset-address="resetForm"
      :quotation-address="quotationAddress"
      @reset="reset"
      @set-form="updateFormAddress"
     />
    <div class="panel-actions">
      <b-button
        size="lg"
        class="mr-3"
        @click.prevent="formEditable ? cancel() : closeForm()"
      >
        {{ formEditable ? 'Cancel' : 'Close' | translate }}
      </b-button>
      <b-button
        size="lg"
        variant="primary"
        v-has-perms="editRolePerm"
        :disabled="formEditable ? $v.$invalid : false"
        @click.prevent="formEditable ? onSubmit() : makeFormEditable()"
      >
        {{ formEditable ? 'Save' : 'Edit' | translate }}
      </b-button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as YARD_TYPES } from './store'
import { ROUTES as YARD_ROUTES } from './router'
import { TYPES as YARD_ADDRESS_TYPES } from '../yard-address/store'
import { TYPES as QUOTATION_TYPES } from '../../quotation/quotation/store'
import { TYPES as PROPOSAL_TYPES } from '../../proposal/yard-proposal-address/store'
import { TYPES as YARD_CONTRACTOR_TYPES } from '../yard-contractor/store'
import { TYPES as YARD_TECHNICIAN_TYPES } from '../yard-technician/store'
import { TYPES as YARD_CONTROL_BODY_TYPES } from '../yard-control-body/store'

import { ROUTES as QUOTATION_ROUTES } from '../../quotation/quotation/router'
import { cloneDeep } from 'lodash'

import ThuxDetailPanelMixin from '../../../components/thux-detail-panel/ThuxDetailPanelMixin'
import YardEditForm from './components/YardEditForm'
import YardAddressForm from './components/YardAddressForm'
import { checkIfEqual } from '../../../const'
import { maxLength, required } from 'vuelidate/lib/validators'
import ContractorManager from './components/contractors/ContractorManager'
import TechnicianManager from './components/technicians/TechnicianManager'
import ControlBodyManager from './components/control-bodies/ControlBodyManager'
import ClientsMixin from '../../proposal/proposal/mixins/ClientsMixin.vue'
import { hasPerm } from '../../../components/PermissionHelper'

export default {
  name: 'YardEdit',
  mixins: [ThuxDetailPanelMixin, ClientsMixin],
  components: {
    TechnicianManager,
    ContractorManager,
    ControlBodyManager,
    'yard-edit-form': YardEditForm,
    'yard-address-form': YardAddressForm
  },
  data () {
    return {
      pageName: 'Yard',
      quotationAddress: undefined,
      rolePerm: 'yard_yard_retrieve',
      editRolePerm: 'yard_yard_update',
      technicianManagerRolePerm: 'yard_yardtechnician_list',
      contractorManagerRolePerm: 'yard_yardcontractor_list',
      controlBodyManagerRolePerm: 'yard_yardcontrolbody_list',
      yardAddressRolePerm: 'yard_yardaddress_list',

      // FORM
      form: {},
      formInitial: {},
      isFormChanged: false,
      resetForm: false,

      // FORM ADDRESS
      formAddress: {},
      formAddressInitial: {},
      isFormAddressChanged: false,
      resetFormAddress: false,

      // FORM CONTRACTORS
      formContractors: [],
      formContractorsInitial: [],
      contractorsToDeleted: new Set(),
      contractorsToChanged: new Set(),
      isFormContractorsChanged: false,
      initializeFormContractors: false,
      resetFormContractors: false,

      // FORM TECHNICIANS
      formTechnicians: [],
      formTechniciansInitial: [],
      techniciansToDeleted: new Set(),
      techniciansToChanged: new Set(),
      isFormTechniciansChanged: false,
      initializeFormTechnicians: false,
      resetFormTechnicians: false,

      // FORM CONTROL BODIES
      formControlBodies: [],
      formControlBodiesInitial: [],
      controlBodiesToDeleted: new Set(),
      controlBodiesToChanged: new Set(),
      isFormControlBodiesChanged: false,
      initializeFormControlBodies: false,
      resetFormControlBodies: false
    }
  },
  created () {
    this.$set(this, 'formEditable', false)
    if (!this.id) {
      this.$set(this, 'formEditable', true)
    }
    this.setDetail({})
  },
  computed: {
    ...mapGetters({
      ...YARD_TYPES.GENERIC.yard.yard.DETAIL.GETTERS,
      yardAddressDetail: YARD_ADDRESS_TYPES.GENERIC.yard.yardaddress.DETAIL.GETTERS.detail,
      techniciansList: YARD_TECHNICIAN_TYPES.GENERIC.yard.yardtechnician.LIST.GETTERS.list,
      controlBodiesList: YARD_CONTROL_BODY_TYPES.GENERIC.yard.yardcontrolbody.LIST.GETTERS.list,
      contractorsList: YARD_CONTRACTOR_TYPES.GENERIC.yard.yardcontractor.LIST.GETTERS.list,
      quotationDetail: QUOTATION_TYPES.GENERIC.quotation.quotation.DETAIL.GETTERS.detail,
      proposalAddressList: PROPOSAL_TYPES.GENERIC.proposal.yardproposaladdress.LIST.GETTERS.list
    })
  },
  methods: {
    ...mapMutations({
      setDetail: YARD_TYPES.GENERIC.yard.yard.DETAIL.MUTATIONS.setDetail,
      setQuotationDetail: QUOTATION_TYPES.GENERIC.quotation.quotation.DETAIL.MUTATIONS.setDetail,
      setYardAddressDetail: YARD_ADDRESS_TYPES.GENERIC.yard.yardaddress.DETAIL.MUTATIONS.setDetail,
      setProposalAddressList: PROPOSAL_TYPES.GENERIC.proposal.yardproposaladdress.LIST.MUTATIONS.setList
    }),
    ...mapActions({
      ...YARD_TYPES.GENERIC.yard.yard.DETAIL.ACTIONS,
      quotationRetrieve: QUOTATION_TYPES.GENERIC.quotation.quotation.DETAIL.ACTIONS.retrieve,

      // yard address
      yardAddressRetrieve: YARD_ADDRESS_TYPES.GENERIC.yard.yardaddress.DETAIL.ACTIONS.retrieve,
      updateYardAddress: YARD_ADDRESS_TYPES.GENERIC.yard.yardaddress.DETAIL.ACTIONS.update,
      createYardAddress: YARD_ADDRESS_TYPES.GENERIC.yard.yardaddress.DETAIL.ACTIONS.create,
      cleanAllYardAddress: YARD_ADDRESS_TYPES.GENERIC.yard.yardaddress.DETAIL.ACTIONS.cleanAllData,
      cleanErrorsYardAddress: YARD_ADDRESS_TYPES.GENERIC.yard.yardaddress.DETAIL.ACTIONS.cleanErrors,
      getProposalAddressList: PROPOSAL_TYPES.GENERIC.proposal.yardproposaladdress.LIST.ACTIONS.setFilters,

      // yard technician
      getTechniciansList: YARD_TECHNICIAN_TYPES.GENERIC.yard.yardtechnician.LIST.ACTIONS.setFilters,
      createTechnician: YARD_TECHNICIAN_TYPES.GENERIC.yard.yardtechnician.DETAIL.ACTIONS.create,
      updateTechnician: YARD_TECHNICIAN_TYPES.GENERIC.yard.yardtechnician.DETAIL.ACTIONS.update,
      deleteTechnician: YARD_TECHNICIAN_TYPES.GENERIC.yard.yardtechnician.DETAIL.ACTIONS.delete,
      cleanAllDataTechniciansList: YARD_TECHNICIAN_TYPES.GENERIC.yard.yardtechnician.LIST.ACTIONS.cleanAllData,

      // yard control body
      getControlBodiesList: YARD_CONTROL_BODY_TYPES.GENERIC.yard.yardcontrolbody.LIST.ACTIONS.setFilters,
      createControlBody: YARD_CONTROL_BODY_TYPES.GENERIC.yard.yardcontrolbody.DETAIL.ACTIONS.create,
      updateControlBody: YARD_CONTROL_BODY_TYPES.GENERIC.yard.yardcontrolbody.DETAIL.ACTIONS.update,
      deleteControlBody: YARD_CONTROL_BODY_TYPES.GENERIC.yard.yardcontrolbody.DETAIL.ACTIONS.delete,
      cleanAllDataControlBodYList: YARD_CONTROL_BODY_TYPES.GENERIC.yard.yardcontrolbody.LIST.ACTIONS.cleanAllData,

      // yard contractor
      getContractorsList: YARD_CONTRACTOR_TYPES.GENERIC.yard.yardcontractor.LIST.ACTIONS.setFilters,
      createContractor: YARD_CONTRACTOR_TYPES.GENERIC.yard.yardcontractor.DETAIL.ACTIONS.create,
      updateContractor: YARD_CONTRACTOR_TYPES.GENERIC.yard.yardcontractor.DETAIL.ACTIONS.update,
      deleteContractor: YARD_CONTRACTOR_TYPES.GENERIC.yard.yardcontractor.DETAIL.ACTIONS.delete,
      cleanAllDataContractorsList: YARD_CONTRACTOR_TYPES.GENERIC.yard.yardcontractor.LIST.ACTIONS.cleanAllData
    }),
    initFields () {
      this.setProposalAddressList({})
      if (this.$route.params.quotationId) {
        this.quotationRetrieve({ id: this.$route.params.quotationId }).then(
          () => {
            this.$set(this.form, 'customer', this.quotationDetail.customer)
            this.$set(this.form, 'customer_name', this.quotationDetail.customer_name)
            this.$set(this.form, 'client', this.quotationDetail.client)
            this.$set(this.form, 'client_name', this.quotationDetail.client_name)
            this.$set(this.form, 'description', this.quotationDetail.description)
            this.$set(this.form, 'name', this.quotationDetail.proposal_yard_name)
            if (this.quotationDetail.proposal) {
              this.getProposalAddressList({ filter__proposal: this.quotationDetail.proposal }).then(
                () => {
                  if (this.proposalAddressList.results.length > 0) {
                    this.$set(this, 'quotationAddress', this.proposalAddressList.results[0])
                  }
                }
              )
            }
          }
        )
      } else {
        this.setQuotationDetail({})
      }
    },
    getTechnicians () {
      this.getTechniciansList({ filter__yard: this.id, no_page: 'no_page' }).then(() => {
        const formTechnicians = cloneDeep(this.techniciansList).map((instance) => {
          if (instance.technician_supplier_role_list) {
            instance.technician_supplier_role_list = instance.technician_supplier_role_list.map((role) => {
              return { id: role, name: role }
            })
          }
          return instance
        })
        this.$set(this, 'formTechnicians', formTechnicians)
        this.$set(this, 'formTechniciansInitial', cloneDeep(this.formTechnicians))
        this.$set(this, 'isFormTechniciansChanged', false)
        this.$set(this, 'initializeFormTechnicians', true)
      })
    },
    getControlBodies () {
      this.getControlBodiesList({ filter__yard: this.id, no_page: 'no_page' }).then(() => {
        const formControlBodies = cloneDeep(this.controlBodiesList)
        this.$set(this, 'formControlBodies', formControlBodies)
        this.$set(this, 'formControlBodiesInitial', cloneDeep(this.formControlBodies))
        this.$set(this, 'isFormControlBodiesChanged', false)
        this.$set(this, 'initializeFormControlBodies', true)
      })
    },
    getContractors () {
      this.getContractorsList({ filter__yard: this.id, no_page: 'no_page' }).then(() => {
        const formContractors = cloneDeep(this.contractorsList).map((instance) => {
          if (instance.contractor_ateco_codes) {
            instance.contractor_ateco_codes = instance.contractor_ateco_codes.split(',').map((ateco) => {
              return { id: ateco, name: ateco }
            })
          }
          return instance
        })
        this.$set(this, 'formContractors', formContractors)
        this.$set(this, 'formContractorsInitial', cloneDeep(this.formContractors))
        this.$set(this, 'isFormContractorsChanged', false)
        this.$set(this, 'initializeFormContractors', true)
      })
    },
    init () {
      this.cleanErrors()
      this.initFields()
      this.$set(this, 'initializeFormTechnicians', false)
      this.$set(this, 'initializeFormContractors', false)
      this.$set(this, 'initializeFormControlBodies', false)
      if (this.id) {
        this.retrieve({ id: this.id }).then(
          () => {
            this.makeFormReadonly()
            this.setForm()
            if (this.detail.yard_address_id) {
              this.yardAddressRetrieve({ id: this.detail.yard_address_id }).then(() => {
                this.$set(this, 'formAddress', cloneDeep(this.yardAddressDetail))
                this.$set(this, 'formAddressInitial', cloneDeep(this.yardAddressDetail))
                this.$set(this, 'isFormAddressChanged', false)
              })
            }
            if (hasPerm(this.technicianManagerRolePerm)) this.getTechnicians()
            if (hasPerm(this.contractorManagerRolePerm)) this.getContractors()
            if (hasPerm(this.controlBodyManagerRolePerm)) this.getControlBodies()
          }
        )
      } else {
        this.$set(this, 'initializeFormTechnicians', false)
        this.$set(this, 'initializeFormContractors', false)
        this.$set(this, 'initializeFormControlBodies', false)
        this.makeFormEditable()
      }
    },
    setForm () {
      const form = Object.assign({}, cloneDeep(this.detail))
      this.$set(this, 'form', form)
      this.$set(this, 'formInitial', form)
      this.$set(this, 'isFormChanged', false)
    },
    closeForm () {
      if (!this.$route.params.quotationId || this.detail.id) {
        this.$router.push({ name: YARD_ROUTES.YARD_LIST })
      } else {
        this.$router.push({ name: QUOTATION_ROUTES.YARD_QUOTATION_LIST })
      }
    },
    updateForm (form) {
      form = { ...this.form, ...form }
      this.$set(this, 'form', form)
      this.$set(this, 'isFormChanged', false)
      // console.log(checkIfEqual(form, this.formInitial))
      if (!checkIfEqual(form, this.formInitial)) {
        this.$set(this, 'isFormChanged', true)
      }
    },
    updateFormAddress (formAddress) {
      formAddress = { ...this.formAddress, ...formAddress }
      this.$set(this, 'formAddress', formAddress)
      this.$set(this, 'isFormAddressChanged', false)
      // console.log(checkIfEqual(formAddress, this.formAddressInitial))
      if (!checkIfEqual(formAddress, this.formAddressInitial)) {
        this.$set(this, 'isFormAddressChanged', true)
      }
    },
    updateTechnicians (technicians, techniciansToDeleted, techniciansToChanged) {
      const formTechnicians = technicians
      this.$set(this, 'formTechnicians', technicians)
      this.$set(this, 'techniciansToDeleted', techniciansToDeleted)
      this.$set(this, 'techniciansToChanged', techniciansToChanged)
      this.$set(this, 'isFormTechniciansChanged', false)
      // console.log(checkIfEqual(formTechnicians, this.formTechniciansInitial))
      if (!checkIfEqual(formTechnicians, this.formTechniciansInitial) || formTechnicians.length !== this.formTechniciansInitial.length) {
        this.$set(this, 'isFormTechniciansChanged', true)
      }
    },
    updateControlBodies (controlBodies, controlBodiesToDeleted, controlBodiesToChanged) {
      const formControlBodies = controlBodies
      this.$set(this, 'formControlBodies', controlBodies)
      this.$set(this, 'controlBodiesToDeleted', controlBodiesToDeleted)
      this.$set(this, 'controlBodiesToChanged', controlBodiesToChanged)
      this.$set(this, 'isFormControlBodiesChanged', false)
      // console.log(checkIfEqual(formControlBodies, this.formTechniciansInitial))
      if (!checkIfEqual(formControlBodies, this.formControlBodiesInitial) || formControlBodies.length !== this.formControlBodiesInitial.length) {
        this.$set(this, 'isFormControlBodiesChanged', true)
      }
    },
    updateContractors (contractors, contractorsToDeleted, contractorsToChanged) {
      const formContractors = contractors
      this.$set(this, 'formContractors', contractors)
      this.$set(this, 'contractorsToDeleted', contractorsToDeleted)
      this.$set(this, 'contractorsToChanged', contractorsToChanged)
      this.$set(this, 'isFormContractorsChanged', false)
      // console.log(checkIfEqual(formContractors, this.formContractorsInitial))
      if (!checkIfEqual(formContractors, this.formContractorsInitial) || formContractors.length !== this.formContractorsInitial.length) {
        this.$set(this, 'isFormContractorsChanged', true)
      }
    },
    cancel () {
      if (!this.id) {
        this.closeForm()
      } else {
        this.setForm()
        this.makeFormReadonly()
        this.$set(this, 'resetForm', !this.resetForm)
        this.$set(this, 'resetFormAddress', !this.resetFormAddress)
        this.$set(this, 'resetFormContractors', !this.resetFormContractors)
        this.$set(this, 'resetFormTechnicians', !this.resetFormTechnicians)
        this.$set(this, 'resetFormControlBodies', !this.resetFormControlBodies)
      }
    },
    reset () {
      this.cancel()
      this.$set(this, 'isFormChanged', false)
      this.$set(this, 'isFormAddressChanged', false)
      this.$set(this, 'formInitial', cloneDeep(this.form))
      this.$set(this, 'formAddressInitial', cloneDeep(this.formAddress))
      this.$set(this, 'formContractors', cloneDeep(this.formContractors))
      this.$set(this, 'formContractorsInitial', cloneDeep(this.formContractors))
      this.$set(this, 'contractorsToDeleted', new Set())
      this.$set(this, 'contractorsToChanged', new Set())
      this.$set(this, 'formTechnicians', cloneDeep(this.formTechnicians))
      this.$set(this, 'formTechniciansInitial', cloneDeep(this.formTechnicians))
      this.$set(this, 'techniciansToDeleted', new Set())
      this.$set(this, 'techniciansToChanged', new Set())
      this.$set(this, 'formControlBodies', cloneDeep(this.formControlBodies))
      this.$set(this, 'formControlBodiesInitial', cloneDeep(this.formControlBodies))
      this.$set(this, 'controlBodiesToDeleted', new Set())
      this.$set(this, 'controlBodiesToChanged', new Set())
    },
    createOrUpdateForm (form, createName, updateName) {
      if (!form.id) {
        form.yard = this.detail.id
        return this[createName](form)
      } else {
        return this[updateName](form)
      }
    },
    createOrUpdateArrayForm (list, createName, updateName, deleteName, instancesToDeleted = [], instancesToChanged = []) {
      const promises = []
      if (instancesToDeleted && instancesToDeleted.size > 0) {
        instancesToDeleted.forEach((id) => {
          promises.push(this[deleteName]({ id: id }))
        })
      }
      list.forEach((form) => {
        if (!form.id) {
          form.yard = this.detail.id
          promises.push(this[createName](form))
        } else {
          if (instancesToChanged.has(form.id)) {
            promises.push(this[updateName](form))
          }
        }
      })
      return promises
    },
    onSubmit () {
      const formData = Object.assign({}, this.form)
      this.setClients(formData)
      const formAddressData = Object.assign({}, this.formAddress)
      let formContractorsData = Object.assign([], this.formContractors)
      formData.contractors = formContractorsData.map(instance => instance.contractor)
      formContractorsData = formContractorsData.map((instance) => {
        return {
          id: instance.id,
          yard: instance.yard,
          contractor: instance.contractor,
          date_yard_entry: instance.date_yard_entry,
          date_yard_exit: instance.date_yard_exit
        }
      })
      let formTechniciansData = Object.assign([], this.formTechnicians)
      formData.technicians = formTechniciansData.map(instance => instance.technician)
      formTechniciansData = formTechniciansData.map((instance) => {
        return {
          id: instance.id,
          yard: instance.yard,
          technician: instance.technician,
          date_yard_entry: instance.date_yard_entry,
          date_yard_exit: instance.date_yard_exit
        }
      })
      let formControlBodiesData = Object.assign([], this.formControlBodies)
      formData.controlBodies = formControlBodiesData.map(instance => instance.contact)
      formControlBodiesData = formControlBodiesData.map((instance) => {
        return {
          id: instance.id,
          yard: instance.yard,
          contact: instance.contact
        }
      })
      const promises = []
      if (!this.id) {
        this.create(formData).then(() => {
          promises.push(this.createOrUpdateForm(
            formAddressData,
            'createYardAddress',
            'updateYardAddress'
          ))
          if (formContractorsData.length > 0) {
            promises.push(...this.createOrUpdateArrayForm(
              formContractorsData,
              'createContractor',
              'updateContractor',
              'deleteContractor',
              this.contractorsToDeleted,
              this.contractorsToChanged
            ))
          }
          if (formTechniciansData.length > 0) {
            promises.push(...this.createOrUpdateArrayForm(
              formTechniciansData,
              'createTechnician',
              'updateTechnician',
              'deleteTechnician',
              this.techniciansToDeleted,
              this.techniciansToChanged
            ))
          }
          if (formControlBodiesData.length > 0) {
            promises.push(...this.createOrUpdateArrayForm(
              formControlBodiesData,
              'createControlBody',
              'updateControlBody',
              'deleteControlBody',
              this.controlBodiesToDeleted,
              this.controlBodiesToChanged
            ))
          }
          Promise.all(promises).then(() => {
            this.reset()
          })
        })
      } else {
        if (this.isFormChanged) {
          promises.push(this.update(formData))
        }
        if (this.isFormAddressChanged) {
          promises.push(this.createOrUpdateForm(
            formAddressData,
            'createYardAddress',
            'updateYardAddress'
          ))
        }
        if (this.isFormContractorsChanged) {
          promises.push(...this.createOrUpdateArrayForm(
            formContractorsData,
            'createContractor',
            'updateContractor',
            'deleteContractor',
            this.contractorsToDeleted,
            this.contractorsToChanged
          ))
        }
        if (this.isFormTechniciansChanged) {
          promises.push(...this.createOrUpdateArrayForm(
            formTechniciansData,
            'createTechnician',
            'updateTechnician',
            'deleteTechnician',
            this.techniciansToDeleted,
            this.techniciansToChanged
          ))
        }
        if (this.isFormControlBodiesChanged) {
          promises.push(...this.createOrUpdateArrayForm(
            formControlBodiesData,
            'createControlBody',
            'updateControlBody',
            'deleteControlBody',
            this.controlBodiesToDeleted,
            this.controlBodiesToChanged
          ))
        }
        Promise.all(promises).then(() => {
          this.reset()
          this.init()
        })
      }
    }
  },
  validations () {
    return {
      form: {
        code: { required },
        name: {},
        clients: { required },
        description: { required },
        date_start: { required },
        date_end: { required },
        work_status: {},
        total_inspections: {},
        technicians: {},
        controlBodies: {},
        contractors: {},
        date_confirmation: {},
        date_activation: {},
        note: {},
        to_do: {}
      },
      formAddress: {
        address_type: {},
        name: { required },
        number: { maxLength: maxLength(20) },
        zip_code: { maxLength: maxLength(20) },
        locality: {},
        province: { maxLength: maxLength(2) },
        country: { required }
      },
      formContractors: {
        $each: {
          contractor: { required },
          date_yard_entry: {},
          date_yard_exit: {}
        }
      },
      formTechnicians: {
        $each: {
          technician: { required },
          date_yard_entry: {},
          date_yard_exit: {}
        }
      },
      formControlBodies: {
        $each: {
          contact: { required }
        }
      }
    }
  }
}
</script>
