<template>
  <panel :title="$t('Yard address')" noButton="true">
    <div>
      <b-form>
        <b-form-row>
          <form-thux-horizontal-autocomplete
            :validator="$v.form.address_type"
            :external-errors="errors['address_type']"
            :label-form="'Address Type' | translate"
            class-form="col-6 mb-2 mt-2"
            :disabled="!formEditableUpdate"
            :label-search="'name'"
            labelColsSm="4"
            labelColsLg="3"
            :value="form.address_type ? { value: form.address_type, name: form.address_type_name } : null"
            :options="addressTypeListOptions"
            :placeholder="$t('Select a address type')"
            :placeholder-field="form.address_type_name"
            @select="selectAddressType"
            @clear="clearAddressType"
          />
          <form-thux-horizontal-input
            :validator="$v.form.name"
            :external-errors="errors['name']"
            :label-form="'Address' | translate"
            class-form="col-lg-6 mb-2 mt-2"
            type="text"
            labelColsSm="3"
            labelColsLg="3"
            :value="form.name"
            :disabled="!formEditableUpdate"
            @change="$set(form, 'name', $event)"
          />
        </b-form-row>
        <b-form-row>
          <form-thux-horizontal-input
            :validator="$v.form.number"
            :external-errors="errors['number']"
            :label-form="'Number' | translate"
            class-form="col-lg-6 mb-2 mt-2"
            type="text"
            labelColsSm="3"
            labelColsLg="3"
            :value="form.number"
            :disabled="!formEditableUpdate"
            @change="$set(form, 'number', $event)"
          />
          <form-thux-horizontal-input
            :validator="$v.form.zip_code"
            :external-errors="errors['zip_code']"
            :label-form="'CAP' | translate"
            class-form="col-lg-6 mb-2 mt-2"
            type="text"
            labelColsSm="3"
            labelColsLg="3"
            :value="form.zip_code"
            :disabled="!formEditableUpdate"
            @change="$set(form, 'zip_code', $event)"
          />
        </b-form-row>
        <b-form-row>
          <form-thux-horizontal-input
            :validator="$v.form.locality"
            :external-errors="errors['locality']"
            :label-form="'Locality' | translate"
            class-form="col-lg-6 mb-2 mt-2"
            type="text"
            labelColsSm="3"
            labelColsLg="3"
            :value="form.locality"
            :disabled="!formEditableUpdate"
            @change="$set(form, 'locality', $event)"
          />
          <form-thux-horizontal-input
            :validator="$v.form.province"
            :external-errors="errors['province']"
            :label-form="'Province (abbr.)' | translate"
            class-form="col-lg-6 mb-2 mt-2"
            type="text"
            labelColsSm="3"
            labelColsLg="3"
            maxlength="2"
            :value="form.province"
            :disabled="!formEditableUpdate"
            @change="$set(form, 'province', $event.toUpperCase())"
          />
          <form-thux-horizontal-autocomplete
            :validator="$v.form.country"
            :external-errors="errors['country']"
            :label-form="'Country' | translate"
            class-form="col-lg-6 mb-2 mt-2"
            open-direction="top"
            labelColsSm="3"
            labelColsLg="3"
            :disabled="!formEditableUpdate"
            :label-search="'name'"
            :value="form.country ? { value: form.country, name: form.country_name } : null"
            :options="countryListOptions"
            :placeholder="$t('Type a country')"
            :placeholder-field="form.country_name"
            @select="selectCountry"
            @clear="clearCountry"
          />
        </b-form-row>
      </b-form>
    </div>
  </panel>
</template>

<script>
import { maxLength, required } from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'

import ThuxDetailPanelMixin from '../../../../components/thux-detail-panel/ThuxDetailPanelMixin.vue'
import AddressScopeMixin from '../../../organization/organization/organization-address/mixins/AddressScopeMixin'
import AddressTypeMixin from '../../../organization/organization/organization-address/mixins/AddressTypeMixin.vue'
import CountryMixin from '../../../organization/organization/organization-address/mixins/CountryMixin'
import { cloneDeep } from 'lodash'

import { TYPES as YARD_TYPES } from '../../yard-address/store'

export default {
  name: 'YardAddressForm',
  props: {
    detail: {},
    formEditableUpdate: {},
    resetAddress: {},
    quotationAddress: {}
  },
  mixins: [ThuxDetailPanelMixin, AddressScopeMixin, CountryMixin, AddressTypeMixin],
  watch: {
    detail: {
      handler () {
        if (this.detail) {
          this.$set(this, 'form', this.setForm(cloneDeep(this.detail)))
        }
      },
      deep: true
    },
    form: {
      handler () {
        this.$emit('set-form', this.form)
      },
      deep: true
    },
    quotationAddress () {
      this.init()
    },
    resetAddress () {
      this.$set(this, 'form', cloneDeep(this.detail))
      this.$emit('set-form', this.form)
    }
  },
  computed: {
    ...mapGetters({
      errors: YARD_TYPES.GENERIC.yard.yardaddress.DETAIL.GETTERS.errors
    })
  },
  methods: {
    init () {
      if (this.quotationAddress && this.quotationAddress.id) {
        this.$set(this.form, 'address_type', this.quotationAddress.address_type)
        this.$set(this.form, 'address_type_name', this.quotationAddress.address_type_name)
        this.$set(this.form, 'name', this.quotationAddress.name)
        this.$set(this.form, 'number', this.quotationAddress.number)
        this.$set(this.form, 'zip_code', this.quotationAddress.zip_code)
        this.$set(this.form, 'locality', this.quotationAddress.locality)
        this.$set(this.form, 'province', this.quotationAddress.province)
        this.$set(this.form, 'country', this.quotationAddress.country)
        this.$set(this.form, 'country_name', this.quotationAddress.country_name)
      } else if (this.id) {
        this.makeFormReadonly()
        this.setForm(this.detail)
      } else {
        this.makeFormEditable()
      }
    },
    setForm (detail) {
      const form = {
        id: detail.id,
        address_type: detail.address_type,
        address_type_name: detail.address_type_name,
        name: detail.name,
        number: detail.number,
        zip_code: detail.zip_code,
        locality: detail.locality,
        province: detail.province,
        country: detail.country,
        country_name: detail.country_name,
        address_scopes: detail.address_scopes
      }
      this.$set(form, 'address_scopes', this.getAddressScopes(form))
      return form
    },
    getFormData () {
      const formData = Object.assign({}, this.form)
      this.setAddressScopes(formData)
      return formData
    }
  },
  validations: {
    form: {
      address_type: {},
      address_scopes: {},
      name: { required },
      number: {
        maxLength: maxLength(20)
      },
      zip_code: {
        maxLength: maxLength(20)
      },
      locality: {},
      province: {
        maxLength: maxLength(2)
      },
      country: { required }
    }
  }
}
</script>
