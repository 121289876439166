<template>
  <b-form>
    <b-form-row>
      <form-thux-horizontal-autocomplete
        :validator="$v.form.contractor"
        :label-form="'Contractor' | translate"
        class-form="input-row-col mb-2 mt-2 mr-2"
        :disabled="!formEditableUpdate"
        :label-search="'name'"
        labelColsSm="0"
        labelColsLg="0"
        :value="form.contractor ? { id: form.contractor, name: form.contractor_typology_name ? `${form.contractor_name} (${form.contractor_typology_name})` :  form.contractor_name } : null"
        :options="contractorListOptions"
        :placeholder="$t('Search by name')"
        :placeholder-field="form.contractor_name"
        @search="onSearchContractor"
        @select="selectContractor($event)"
        @clear="clearContractor()"
      />
      <b-btn-group class="col-xl-1 btn-row-actions my-2">
        <b-btn
          v-if="showAddButton"
          class="mr-1"
          variant="primary"
          :disabled="$v.form.$invalid || !formEditableUpdate"
          @click="addYardContractor
        ">
          <i class="fas fa-plus"></i>
        </b-btn>
        <b-btn class="mr-1" variant="outline-primary" @click="removeYardContractor"
          :disabled="!formEditableUpdate">
          <i :class="index === 0 && contractorsLength === 1 ? 'fas fa-eraser' : 'fas fa-minus'"></i>
        </b-btn>
      </b-btn-group>
    </b-form-row>
    <b-form-row>
      <form-thux-horizontal-multiselect
        :label-form="$t('Ateco codes')"
        labelColsSm="0"
        labelColsLg="0"
        class-form="input-row-col mb-2 mt-2"
        :disabled="true"
        :value="form.contractor_ateco_codes ? form.contractor_ateco_codes.map((instance) => instance.name) : []"
        :options="form.contractor_ateco_codes || []"
      />
    </b-form-row>
    <b-form-row>
      <form-thux-horizontal-text-area
        :label-form="'Activity description' | translate"
        labelColsSm="0"
        labelColsLg="0"
        class-form="input-row-col mb-2 mt-2"
        rows="2"
        max-rows="6"
        :disabled="true"
        :value="form.contractor_activity_desc"
      />
    </b-form-row>
    <b-form-row>
      <form-thux-horizontal-datetime
        :validator="$v.form.date_yard_entry"
        :label-form="'Date yard entry' | translate"
        labelColsSm="0"
        labelColsLg="0"
        class-form="input-row-col mb-2 mt-2 mr-2"
        type="date"
        :disabled="!formEditableUpdate"
        :value="dateYardEntry"
        :placeholder="$t('Enter the date yard entry')"
        @change="dateChange($event, 'date_yard_entry')"
      />
      <form-thux-horizontal-datetime
        :validator="$v.form.date_yard_exit"
        :label-form="'Date yard exit' | translate"
        labelColsSm="0"
        labelColsLg="0"
        class-form="input-row-col mb-2 mt-2 mr-2"
        type="date"
        :disabled="!formEditableUpdate"
        :value="dateYardExit"
        :placeholder="$t('Enter the date yard exit')"
        @change="dateChange($event, 'date_yard_exit')"
      />
    </b-form-row>

  </b-form>
</template>

<script>
import ThuxDetailPanelMixin from '../../../../../components/thux-detail-panel/ThuxDetailPanelMixin'
import ContractorMixin from '../../mixins/ContractorMixin'
import { required } from 'vuelidate/lib/validators'
import moment from 'moment'

export default {
  name: 'ContractorForm',
  mixins: [ThuxDetailPanelMixin, ContractorMixin],
  props: {
    formUpdate: {},
    index: {},
    contractorsLength: {},
    showAddButton: {},
    formEditableUpdate: {}
  },
  watch: {
    formUpdate: {
      handler () {
        if (this.formUpdate) {
          this.$set(this, 'form', this.formUpdate)
          if (this.form.date_yard_entry) {
            this.$set(this, 'dateYardEntry', moment(new Date(this.form.date_yard_entry)))
          } else {
            this.$set(this, 'dateYardEntry', null)
          }
          if (this.form.date_yard_exit) {
            this.$set(this, 'dateYardExit', moment(new Date(this.form.date_yard_exit)))
          } else {
            this.$set(this, 'dateYardExit', null)
          }
        }
      },
      deep: true
    }
  },
  data () {
    return {
      form: {},
      dateYardEntry: undefined,
      dateYardExit: undefined
    }
  },
  methods: {
    init () {
      if (this.formUpdate) {
        this.$set(this, 'form', this.formUpdate)
        if (this.form.date_yard_entry) {
          this.$set(this, 'dateYardEntry', moment(new Date(this.form.date_yard_entry)))
        }
        if (this.form.date_yard_exit) {
          this.$set(this, 'dateYardExit', moment(new Date(this.form.date_yard_exit)))
        }
      }
    },
    dateChange (evt, fieldName, format = 'YYYY-MM-DD') {
      const date = evt.date
      if (date) {
        this.$set(this.form, fieldName, date.format(format))
      } else {
        this.$set(this.form, fieldName, null)
      }
      this.$emit('edit-yard-contractor', this.form)
    },
    checkIfContractorEmpty () {
      if (this.form.contractor && this.form.contractor !== '') return false
      if (this.form.date_yard_entry && this.form.date_yard_entry !== '') return false
      if (this.form.date_yard_exit && this.form.date_yard_exit !== '') return false
      return true
    },
    addYardContractor () {
      this.$emit('add-yard-contractor', this.$v.form.$model)
    },
    removeYardContractor () {
      if (this.contractorsLength === 1 && (!this.id || !this.form.id)) {
        this.$set(this, 'form', {})
        this.$set(this, 'dateYardEntry', null)
        this.$set(this, 'dateYardExit', null)
      }
      this.$emit('remove-yard-contractor', this.$v.form.$model)
    }
  },
  validations () {
    let form = {
      contractor: { required },
      date_yard_entry: {}
    }
    if (this.checkIfContractorEmpty()) {
      form = {
        contractor: {},
        date_yard_entry: {},
        date_yard_exit: {}
      }
    }
    return { form: form }
  }
}
</script>
