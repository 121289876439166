<template>
  <div>
    <panel :title="'Control bodies' | translate" noButton="true">
      <div class="col-12 control-body-items panel-separator px-0" v-for="(controlbody, index) in controlBodies" :key="index">
        <control-body-form
          :id="id"
          :controlBodies="controlBodies"
          :form-editable-update="formEditableUpdate"
          :form-update="controlbody"
          :show-add-button="index === controlBodies.length - 1"
          :controlBodies-length="controlBodies.length"
          :index="index"
          @add-yard-control-body="addNewYardControlBodies"
          @edit-yard-control-body="editYardControlBodies($event, index)"
          @show-delete-modal="showConfirmModal($event, index)"
          @remove-yard-control-body="showConfirmModal({ instance: $event }, index, true)"
        />
      </div>
    </panel>
    <ConfirmDialog
      v-if="showModal && controlBodyToDelete.value && controlBodyToDelete.value.contact"
      ref-id="modal-delete"
      :title="$t('Are you sure to remove this controller?')"
      :button-confirm-text="'Remove' | translate"
      @confirm-dialog-close="closeConfirmModal"
      @confirm-dialog-select="removeOrReplaceControlBody()"
    >
        <template slot="body">
          <div class="mb-8">
            <span style="font-size: 1.2rem">
              {{ $t("To make the changes effective you need to press the 'Save' button at the bottom page") }}
            </span>
          </div>
          <b-table
            no-local-sorting
            show-empty
            striped
            hover
            responsive
            :items="[controlBodyToDelete.value]"
            :fields="controlBodyFields"
          >
          </b-table>
      </template>
    </ConfirmDialog>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { cloneDeep } from 'lodash'

import ControlBodyForm from './ControlBodyForm'
import ConfirmDialog from '../../../../../components/ConfirmDialog'

export default {
  name: 'ControlBodyManager',
  props: {
    id: {},
    formEditableUpdate: {},
    controlBodiesList: [],
    resetControlBodies: {}
  },
  components: {
    ControlBodyForm,
    ConfirmDialog
  },
  watch: {
    controlBodies: {
      handler () {
        let controlBodies = [...this.controlBodies]
        controlBodies = controlBodies.filter((controlBody) => {
          return Object.values(controlBody).some((value) => { return !!value })
        })
        this.$emit(
          'update-control-bodies',
          controlBodies,
          this.controlBodiesToDeleted,
          this.controlBodiesToChanged
        )
      },
      deep: true
    },
    resetControlBodies () {
      this.init()
    }
  },
  data () {
    return {
      controlBodies: [{}],
      controlBodiesToDeleted: new Set(),
      controlBodiesToChanged: new Set(),
      showModal: false,
      controlBodyToDelete: {},
      controlBodyToReplace: null,
      controlBodyFields: [
        {
          key: 'contact_first_name',
          label: this.$t('First name'),
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap'
        },
        {
          key: 'contact_last_name',
          label: this.$t('Last name'),
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap'
        }
      ]
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      if (this.controlBodiesList && this.controlBodiesList.length > 0) {
        this.$set(this, 'controlBodies', cloneDeep(this.controlBodiesList))
      } else {
        this.$set(this, 'controlBodies', [{}])
      }
    },
    addNewYardControlBodies () {
      this.controlBodies.push({})
    },
    editYardControlBodies (yardControlBodies, index) {
      if (yardControlBodies.id) {
        this.controlBodiesToChanged.add(yardControlBodies.id)
      }
      this.$set(this.controlBodies, index, yardControlBodies)
    },
    removeOrReplaceControlBody () {
      if (!this.controlBodyToDelete.deleteYardControlBody) {
        if (this.controlBodyToReplace) {
          this.$set(this.controlBodies[this.controlBodyToReplace.index], 'contact', this.controlBodyToReplace.value.contact)
          this.$set(this.controlBodies[this.controlBodyToReplace.index], 'contact_first_name', this.controlBodyToReplace.value.contact_first_name)
          this.$set(this.controlBodies[this.controlBodyToReplace.index], 'contact_last_name', this.controlBodyToReplace.value.contact_last_name)
        } else {
          this.$set(this.controlBodies[this.controlBodyToDelete.index], 'contact', null)
          this.$set(this.controlBodies[this.controlBodyToDelete.index], 'contact_first_name', null)
          this.$set(this.controlBodies[this.controlBodyToDelete.index], 'contact_last_name', null)
        }
        if (this.controlBodyToDelete.value?.id) {
          this.controlBodiesToChanged.add(this.controlBodyToDelete.value.id)
        }
      } else {
        if (this.controlBodyToDelete.value?.id) {
          this.controlBodiesToDeleted.add(this.controlBodyToDelete.value.id)
          this.controlBodiesToChanged.delete(this.controlBodyToDelete.value.id)
        }
        if (this.controlBodies.length === 1) {
          this.controlBodies[0] = {}
        } else {
          if (this.controlBodyToReplace) {
            this.$set(this.controlBodies[this.controlBodyToReplace.index], 'contact', this.controlBodyToReplace.value.contact)
            this.$set(this.controlBodies[this.controlBodyToReplace.index], 'contact_first_name', this.controlBodyToReplace.value.contact_first_name)
            this.$set(this.controlBodies[this.controlBodyToReplace.index], 'contact_last_name', this.controlBodyToReplace.value.contact_last_name)
          } else {
            this.controlBodies.splice(this.controlBodyToDelete.index, 1)
          }
        }
      }
      this.closeConfirmModal()
    },
    showConfirmModal ({ instance, newInstance }, index, deleteYardControlBody = false) {
      if (this.id && instance.id) {
        this.$set(this, 'controlBodyToDelete', { index: index, value: instance })
        if (deleteYardControlBody) {
          this.$set(this, 'controlBodyToDelete', { index: index, value: instance, deleteYardControlBody: deleteYardControlBody })
        }
        if (newInstance) {
          this.$set(this, 'controlBodyToReplace', { index: index, value: newInstance })
        }
        this.$set(this, 'showModal', true)
      } else {
        if (this.controlBodies.length === 1 && deleteYardControlBody) {
          this.controlBodies[0] = {}
        } else if (this.controlBodies.length > 1 && deleteYardControlBody) {
          this.controlBodies.splice(index, 1)
        } else {
          if (newInstance) {
            this.$set(this.controlBodies[index], 'contact', newInstance.contact)
            this.$set(this.controlBodies[index], 'contact_first_name', newInstance.contact_first_name)
            this.$set(this.controlBodies[index], 'contact_last_name', newInstance.contact_last_name)
          } else {
            this.$set(this.controlBodies[index], 'contact', null)
            this.$set(this.controlBodies[index], 'contact_first_name', null)
            this.$set(this.controlBodies[index], 'contact_last_name', null)
          }
        }
      }
    },
    closeConfirmModal () {
      this.$set(this, 'controlBodyToDelete', {})
      this.$set(this, 'controlBodyToReplace', null)
      this.$set(this, 'showDeleteModal', false)
    }
  },
  validations: {
    controlBodies: {
      $each: {
        contact: { required }
      }
    }
  }
}
</script>
