<script>
import { mapActions, mapGetters } from 'vuex'
import { TYPES as YARD_TYPES } from '../store'

export default {
  name: 'WorkStatusMixin',
  created () {
    if (!this.workStatusList || (this.workStatusList && this.workStatusList.length === 0)) {
      this.getWorkStatusList()
    }
  },
  computed: {
    ...mapGetters({
      workStatusList: YARD_TYPES.GENERIC.yard.yard.LIST.GETTERS.workStatusChoices
    }),
    workStatusListOptions () {
      const options = []
      if (this.workStatusList && this.workStatusList.length > 0) {
        this.workStatusList.forEach((workStatus) => {
          const option = {
            value: workStatus[0],
            text: workStatus[1]
          }
          options.push(option)
        })
      }
      return options
    }
  },
  methods: {
    ...mapActions({
      getWorkStatusList: YARD_TYPES.GENERIC.yard.yard.LIST.ACTIONS.getWorkstatuschoices
    }),
    selectWorkStatus (item) {
      this.$set(this.form, 'work_status', item.value)
      this.$set(this.form, 'work_status_name', item.text)
    },
    clearWorkStatus () {
      this.$set(this.form, 'work_status', null)
      this.$set(this.form, 'work_status_name', null)
    }
  }
}
</script>
