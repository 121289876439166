<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { vue } from '@/main'
import { TYPES as ORGANIZATION_TYPES } from '../../../organization/organization/store'

export default {
  name: 'ContractorMixin',
  props: ['contractors'],
  created () {
    if (!this.contractorList || (this.contractorList && this.contractorList.length === 0)) {
      this.setContractorList({})
    }
  },
  data () {
    return {
      form: {}
    }
  },
  computed: {
    ...mapGetters({
      contractorList: ORGANIZATION_TYPES.GENERIC.organization.organization.LIST.GETTERS.list
    }),
    contractorListOptions () {
      const options = []
      if (this.contractorList && this.contractorList.results && this.contractorList.results.length > 0) {
        this.contractorList.results.forEach((contractor) => {
          const option = {
            id: contractor.id,
            name: contractor.typology_name ? `${contractor.name} (${contractor.typology_name})` : contractor.name,
            ateco_codes: contractor.ateco_codes,
            activity_desc: contractor.activity_desc
          }
          if (option.ateco_codes) {
            option.ateco_codes = option.ateco_codes.split(',').map((ateco) => {
              return { id: ateco, name: ateco }
            })
          } else {
            option.ateco_codes = []
          }
          if (this.contractors && this.contractors.length > 0) {
            if (!this.contractors.some((instance) => { return option.id === instance.contractor })) {
              options.push(option)
            }
          } else {
            options.push(option)
          }
        })
      }
      return options
    }
  },
  methods: {
    ...mapMutations({
      setContractorList: ORGANIZATION_TYPES.GENERIC.organization.organization.LIST.MUTATIONS.setList
    }),
    ...mapActions({
      getContractorList: ORGANIZATION_TYPES.GENERIC.organization.organization.LIST.ACTIONS.setFilters
    }),
    onSearchContractor (search) {
      if (search.length >= 2) {
        vue.$store.commit('componentLoading', false)
        this.getContractorList({ filter__name__icontains: search }).then(() => {
          vue.$store.commit('componentLoading', true)
        })
      } else {
        this.setContractorList({})
      }
    },
    selectContractor (item) {
      this.setContractorList({})
      const newInstance = {
        contractor: item.id,
        contractor_name: item.name,
        contractor_ateco_codes: item.ateco_codes,
        contractor_activity_desc: item.activity_desc
      }
      if (this.id && this.form.contractor && this.form.contractor_name) {
        this.$emit('show-delete-modal', { instance: this.form, newInstance: newInstance })
        return
      }
      this.$set(this.form, 'contractor', item.id)
      this.$set(this.form, 'contractor_name', item.name)
      this.$set(this.form, 'contractor_ateco_codes', item.ateco_codes)
      this.$set(this.form, 'contractor_activity_desc', item.activity_desc)
    },
    clearContractor () {
      this.setContractorList({})
      if (this.id) {
        this.$emit('show-delete-modal', { instance: this.form })
        return
      }
      this.$set(this.form, 'contractor', null)
      this.$set(this.form, 'contractor_name', null)
      this.$set(this.form, 'contractor_ateco_codes', null)
      this.$set(this.form, 'contractor_activity_desc', null)
    }
  }
}
</script>
