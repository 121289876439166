<template>
  <b-form>
    <b-form-row>
      <form-thux-horizontal-autocomplete
        :validator="$v.form.contact"
        :label-form="'Control body' | translate"
        :disabled="!formEditableUpdate"
        :label-search="'name'"
        labelColsSm="0"
        labelColsLg="0"
        class-form="input-row-col mb-2 mt-2"
        :value="form.contact ? { id: form.contact, name: `${form.contact_first_name} ${form.contact_last_name}` } : null"
        :options="controlBodyListOptions"
        :placeholder="$t('Search by last name')"
        :placeholder-field="form.contact ?  `${form.contact_first_name} ${form.contact_last_name}` : null"
        @search="onSearchControlBody"
        @select="selectControlBody($event)"
        @clear="clearControlBody()"
      />
      <b-btn-group class="col-xl-1 btn-row-actions my-2">
        <b-btn
          v-if="showAddButton"
          class="mr-1"
          variant="primary"
          :disabled="$v.form.$invalid || !formEditableUpdate"
          @click="addYardControlBody
        ">
          <i class="fas fa-plus"></i>
        </b-btn>
        <b-btn class="mr-1" variant="outline-primary" @click="removeYardControlBody"
          :disabled="!formEditableUpdate">
          <i :class="index === 0 && controlBodiesLength === 1 ? 'fas fa-eraser' : 'fas fa-minus'"></i>
        </b-btn>
      </b-btn-group>
    </b-form-row>
    <!-- <b-form-row>

    </b-form-row> -->
  </b-form>
</template>

<script>
import ThuxDetailPanelMixin from '../../../../../components/thux-detail-panel/ThuxDetailPanelMixin'
import ControlBodyMixin from '../../mixins/ControlBodyMixin'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'ControlBodyForm',
  mixins: [ThuxDetailPanelMixin, ControlBodyMixin],
  props: {
    formUpdate: {},
    index: {},
    controlBodiesLength: {},
    showAddButton: {},
    formEditableUpdate: {}
  },
  watch: {
    formUpdate: {
      handler () {
        if (this.formUpdate) {
          this.$set(this, 'form', this.formUpdate)
        }
      },
      deep: true
    }
  },
  data () {
    return {
      form: {}
    }
  },
  methods: {
    init () {
      if (this.formUpdate) {
        this.$set(this, 'form', this.formUpdate)
      }
    },
    checkIfControlBodyEmpty () {
      if (this.form.contact && this.form.contact !== '') return false
      return true
    },
    addYardControlBody () {
      this.$emit('add-yard-control-body', this.$v.form.$model)
    },
    removeYardControlBody () {
      if (this.controlBodiesLength === 1 && (!this.id || !this.form.id)) {
        this.$set(this, 'form', {})
      }
      this.$emit('remove-yard-control-body', this.$v.form.$model)
    }
  },
  validations () {
    let form = {
      contact: { required }
    }
    if (this.checkIfControlBodyEmpty()) {
      form = {
        contact: {}
      }
    }
    return { form: form }
  }
}
</script>
