<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { vue } from '@/main'
import { TYPES as ORGANIZATION_TYPES } from '../../../organization/organization/organization/customer-organization/store'

export default {
  name: 'YardCustomerMixin',
  created () {
    if (!this.customerOrgList || (this.customerOrgList && this.customerOrgList.length === 0)) {
      this.setCustomerOrgList({})
    }
  },
  computed: {
    ...mapGetters({
      customerOrgList: ORGANIZATION_TYPES.GENERIC.organization.customerorganization.LIST.GETTERS.list,
      customerOrgDetail: ORGANIZATION_TYPES.GENERIC.organization.customerorganization.DETAIL.GETTERS.detail
    }),
    yardCustomerListOptions () {
      const options = []
      if (this.customerOrgList && this.customerOrgList.results && this.customerOrgList.results.length > 0) {
        this.customerOrgList.results.forEach((customer) => {
          options.push({
            value: customer.id,
            text: `${customer.name}`
          })
        })
      }
      return options
    }
  },
  methods: {
    ...mapMutations({
      setCustomerOrgList: ORGANIZATION_TYPES.GENERIC.organization.customerorganization.LIST.MUTATIONS.setList,
      setCustomerOrgDetail: ORGANIZATION_TYPES.GENERIC.organization.customerorganization.DETAIL.MUTATIONS.setDetail
    }),
    ...mapActions({
      getCustomerOrgList: ORGANIZATION_TYPES.GENERIC.organization.customerorganization.LIST.ACTIONS.setFilters,
      getCustomerOrgDetail: ORGANIZATION_TYPES.GENERIC.organization.customerorganization.DETAIL.ACTIONS.retrieve
    }),
    onSearchYardCustomer (search) {
      if (search.length >= 2) {
        vue.$store.commit('componentLoading', false)
        this.getCustomerOrgList({ filter__name__icontains: search }).then(() => {
          vue.$store.commit('componentLoading', true)
        })
      } else {
        this.setCustomerOrgList({})
      }
    },
    selectYardCustomer (item) {
      this.$set(this.form, 'customer', item.value)
      this.$set(this.form, 'customer_name', item.text)
    },
    clearYardCustomer () {
      this.$set(this.form, 'customer', null)
      this.$set(this.form, 'customer_name', null)
    }
  }
}
</script>
