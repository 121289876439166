<template>
  <div>
    <panel :title="'Contractors' | translate" noButton="true">
      <div class="col-12 contractor-items panel-separator px-0" v-for="(contractor, index) in contractors" :key="index">
        <contractor-form
          :id="id"
          :contractors="contractors"
          :form-editable-update="formEditableUpdate"
          :form-update="contractor"
          :show-add-button="index === contractors.length - 1"
          :contractors-length="contractors.length"
          :index="index"
          @add-yard-contractor="addNewYardContractor"
          @edit-yard-contractor="editYardContractor($event, index)"
          @show-delete-modal="showConfirmModal($event, index)"
          @remove-yard-contractor="showConfirmModal({ instance: $event }, index, true)"
        />
      </div>
    </panel>
    <ConfirmDialog
      v-if="showModal && contractorToDelete.value && contractorToDelete.value.contractor"
      ref-id="modal-delete"
      :title="$t('Are you sure to remove this contractor?')"
      :button-confirm-text="'Remove' | translate"
      @confirm-dialog-close="closeConfirmModal"
      @confirm-dialog-select="removeOrReplaceContractor()"
    >
        <template slot="body">
          <div class="mb-8">
            <span style="font-size: 1.2rem">
              {{ $t("To make the changes effective you need to press the 'Save' button at the bottom page") }}
            </span>
          </div>
          <b-table
            no-local-sorting
            show-empty
            striped
            hover
            responsive
            :items="[contractorToDelete.value]"
            :fields="contractorFields"
          >
            <template slot="cell(contractor_ateco_codes)" slot-scope="row">
              <div v-if="row.item.contractor_ateco_codes">
                <span
                  v-for="ateco in row.item.contractor_ateco_codes"
                  :key="ateco.id"
                  class="tag"
                >
                  {{ ateco.name }}
                </span>
              </div>
            </template>
          </b-table>
      </template>
    </ConfirmDialog>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { cloneDeep } from 'lodash'

import ContractorForm from './ContractorForm'
import ConfirmDialog from '../../../../../components/ConfirmDialog'

export default {
  name: 'ContractorManager',
  props: {
    id: {},
    formEditableUpdate: {},
    contractorsList: [],
    resetContractors: {}
  },
  components: {
    ContractorForm,
    ConfirmDialog
  },
  watch: {
    contractors: {
      handler () {
        let contractors = [...this.contractors]
        contractors = contractors.filter((contractor) => {
          return Object.values(contractor).some((value) => { return !!value })
        })
        this.$emit(
          'update-contractors',
          contractors,
          this.contractorsToDeleted,
          this.contractorsToChanged
        )
      },
      deep: true
    },
    resetContractors () {
      this.init()
    }
  },
  data () {
    return {
      contractors: [{}],
      contractorsToDeleted: new Set(),
      contractorsToChanged: new Set(),
      showModal: false,
      contractorToDelete: {},
      contractorToReplace: null,
      contractorFields: [
        {
          key: 'contractor_name',
          label: this.$t('Name'),
          type: 'text',
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap'
        },
        {
          key: 'contractor_ateco_codes',
          label: this.$t('Ateco codes'),
          tdClass: 'text-nowrap',
          thClass: 'text-nowrap'
        },
        {
          key: 'contractor_activity_desc',
          label: this.$t('Activity description'),
          tdClass: 'text-nowrap',
          thClass: 'text-nowrap'
        }
      ]
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      if (this.contractorsList && this.contractorsList.length > 0) {
        this.$set(this, 'contractors', cloneDeep(this.contractorsList))
      } else {
        this.$set(this, 'contractors', [{}])
      }
    },
    addNewYardContractor () {
      this.contractors.push({})
    },
    editYardContractor (yardContractor, index) {
      if (yardContractor.id) {
        this.contractorsToChanged.add(yardContractor.id)
      }
      this.$set(this.contractors, index, yardContractor)
    },
    removeOrReplaceContractor () {
      if (!this.contractorToDelete.deleteYardContractor) {
        if (this.contractorToReplace) {
          this.$set(this.contractors[this.contractorToReplace.index], 'contractor', this.contractorToReplace.value.contractor)
          this.$set(this.contractors[this.contractorToReplace.index], 'contractor_name', this.contractorToReplace.value.contractor_name)
          this.$set(this.contractors[this.contractorToReplace.index], 'contractor_ateco_codes', this.contractorToReplace.value.contractor_ateco_codes)
          this.$set(this.contractors[this.contractorToReplace.index], 'contractor_activity_desc', this.contractorToReplace.value.contractor_activity_desc)
        } else {
          this.$set(this.contractors[this.contractorToDelete.index], 'contractor', null)
          this.$set(this.contractors[this.contractorToDelete.index], 'contractor_name', null)
          this.$set(this.contractors[this.contractorToDelete.index], 'contractor_ateco_codes', null)
          this.$set(this.contractors[this.contractorToDelete.index], 'contractor_activity_desc', null)
        }
        if (this.contractorToDelete.value?.id) {
          this.contractorsToChanged.add(this.contractorToDelete.value.id)
        }
      } else {
        if (this.contractorToDelete.value?.id) {
          this.contractorsToDeleted.add(this.contractorToDelete.value.id)
          this.contractorsToChanged.delete(this.contractorToDelete.value.id)
        }
        if (this.contractors.length === 1) {
          this.contractors[0] = {}
        } else {
          if (this.contractorToReplace) {
            this.$set(this.contractors[this.contractorToReplace.index], 'contractor', this.contractorToReplace.value.contractor)
            this.$set(this.contractors[this.contractorToReplace.index], 'contractor_name', this.contractorToReplace.value.contractor_name)
            this.$set(this.contractors[this.contractorToReplace.index], 'contractor_ateco_codes', this.contractorToReplace.value.contractor_ateco_codes)
            this.$set(this.contractors[this.contractorToReplace.index], 'contractor_activity_desc', this.contractorToReplace.value.contractor_activity_desc)
          } else {
            this.contractors.splice(this.contractorToDelete.index, 1)
          }
        }
      }
      this.closeConfirmModal()
    },
    showConfirmModal ({ instance, newInstance }, index, deleteYardContractor = false) {
      if (this.id && instance.id) {
        this.$set(this, 'contractorToDelete', { index: index, value: instance })
        if (deleteYardContractor) {
          this.$set(this, 'contractorToDelete', { index: index, value: instance, deleteYardContractor: deleteYardContractor })
        }
        if (newInstance) {
          this.$set(this, 'contractorToReplace', { index: index, value: newInstance })
        }
        this.$set(this, 'showModal', true)
      } else {
        if (this.contractors.length === 1 && deleteYardContractor) {
          this.contractors[0] = {}
        } else if (this.contractors.length > 1 && deleteYardContractor) {
          this.contractors.splice(index, 1)
        } else {
          if (newInstance) {
            this.$set(this.contractors[index], 'contractor', newInstance.contractor)
            this.$set(this.contractors[index], 'contractor_name', newInstance.contractor_name)
            this.$set(this.contractors[index], 'contractor_ateco_codes', newInstance.contractor_ateco_codes)
            this.$set(this.contractors[index], 'contractor_activity_desc', newInstance.contractor_activity_desc)
          } else {
            this.$set(this.contractors[index], 'contractor', null)
            this.$set(this.contractors[index], 'contractor_name', null)
            this.$set(this.contractors[index], 'contractor_ateco_codes', null)
            this.$set(this.contractors[index], 'contractor_activity_desc', null)
          }
        }
      }
    },
    closeConfirmModal () {
      this.$set(this, 'contractorToDelete', {})
      this.$set(this, 'contractorToReplace', null)
      this.$set(this, 'showDeleteModal', false)
    }
  },
  validations: {
    contractors: {
      $each: {
        contractor: { required },
        date_yard_entry: {},
        date_yard_exit: {}
      }
    }
  }
}
</script>
